import moment from "moment";

import leboncoinLogo from "../images/website/leboncoin-icon.png";
import lacentraleLogo from "../images/website/lacentrale-icon.png";
import leparkingLogo from "../images/website/leparking-icon.png";
import autoscout24Logo from "../images/website/autoscout24-icon.png";
import paruvenduLogo from "../images/website/paruvendu-icon.png";
import largusLogo from "../images/website/largus-icon.png";
import luxautoLogo from "../images/website/luxauto-icon.png";
import mobileLogo from "../images/website/mobile-icon.png";
import evalcarLogo from "../images/website/evalcar-icon.png";

const getGender = (string) => {
  switch (string) {
    case "M1":
      return 0;
    case "L1":
      return 1;
    case "L2":
      return 1;
    case "L3":
      return 1;
    case "L4":
      return 1;
    case "L5":
      return 1;
    case "L6":
      return 1;
    case "L7":
      return 1;
    case "N1":
      return 2;
    case "N2":
      return 2;
    case "N3":
      return 2;
    default:
      return null;
  }
};

const getFuel = (string) => {
  switch (string) {
    case "GO":
      return 2;
    case "ES":
      return 1;
    case "EL":
      return 4;
    case "HY":
      return 6;
    default:
      return "DEFAULT";
  }
};

const getFuelString = (value) => {
  switch (value) {
    case 2:
      return "Diesel";
    case 1:
      return "Essence";
    case 4:
      return "Électrique";
    case 6:
      return "Hybride";
    default:
      return "Autre";
  }
};

const getGearboxString = (value) => {
  switch (value) {
    case 1:
      return "Manuelle";
    case 2:
      return "Automatique";
    default:
      return "Autre";
  }
};

const getPrice = (value, decimal = 2) => {
  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: decimal,
  }).format(value);
};

const getInt = (value, decimal = 2) => {
  return new Intl.NumberFormat("fr-FR", {
    maximumFractionDigits: decimal,
  }).format(value);
};

const getIcon = (source, url = "") => {
  switch (source) {
    case "leboncoin":
      return leboncoinLogo;
    case "lacentrale":
      return lacentraleLogo;
    case "leparking":
      return leparkingLogo;
    case "autoscout24":
      return autoscout24Logo;
    case "paruvendu":
      return paruvenduLogo;
    case "largus":
      return largusLogo;
    case "luxauto":
      return luxautoLogo;
    case "mobile":
      return mobileLogo;
    case "evalcar":
      if (url.includes("leboncoin")) {
        return leboncoinLogo;
      } else if (url.includes("lacentrale")) {
        return lacentraleLogo;
      } else if (url.includes("leparking")) {
        return leparkingLogo;
      } else if (url.includes("autoscout24")) {
        return autoscout24Logo;
      } else if (url.includes("paruvendu")) {
        return paruvenduLogo;
      } else if (url.includes("largus")) {
        return largusLogo;
      } else if (url.includes("luxauto")) {
        return luxautoLogo;
      } else if (url.includes("mobile")) {
        return mobileLogo;
      } else {
        return evalcarLogo;
      }
    default:
      return null;
  }
};

const getAverage = (data, name = null) => {
  let total = 0;

  data.map((element) => {
    if (name) {
      total += parseFloat(element[name]);
    } else {
      total += parseFloat(element);
    }
    return null;
  });

  if (total) {
    return total / data.length;
  } else {
    return null;
  }
};

const getDifferenceDays = (start, end) => {
  if (start && end) {
    var duration = moment.duration(end.diff(start));
    return parseInt(duration.asDays());
  } else {
    return null;
  }
};

const getDifferenceMonths = (start, end) => {
  if (start && end) {
    var duration = moment.duration(end.diff(start));
    return parseInt(duration.asMonths());
  } else {
    return null;
  }
};

const getDifferenceYears = (start, end) => {
  if (start && end) {
    var duration = moment.duration(end.diff(start));
    return parseInt(duration.asYears());
  } else {
    return null;
  }
};

const checkObject = (data) => {
  if (data) {
    return data;
  } else {
    return {};
  }
};

export {
  getGender,
  getFuel,
  getFuelString,
  getPrice,
  getInt,
  getIcon,
  getAverage,
  getDifferenceDays,
  getDifferenceMonths,
  getDifferenceYears,
  getGearboxString,
  checkObject,
};
