import React, { Fragment } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Button, ButtonIcon, Card, FormTextarea } from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import ModalParts from "./vehicle/parts/ModalParts";
import Table from "./vehicle/Table";
import Thead from "./vehicle/Thead";
import Tbody from "./vehicle/Tbody";
import Tr from "./vehicle/Tr";
import Th from "./vehicle/Th";
import Td from "./vehicle/Td";

import Car from "./vehicle/svg/Car";
import Bike from "./vehicle/svg/Bike";
import Commercial from "./vehicle/svg/Commercial";
import Caravaning1 from "./vehicle/svg/Caravaning1";
import Caravaning2 from "./vehicle/svg/Caravaning2";
import Wheel from "./vehicle/svg/Wheel";

import getParts from "./vehicle/parts/parts";
import { getPrice, getDifferenceYears } from "../../../utils/general";

export default class Vehicle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalParts: false,
      modalPartsName: null,
      dataPart: null,
      dataAdministrative: {},
      dataComment: "",
    };

    this.vehicleRef = null;
  }

  handleInputChange = (e) => {
    const { value, name } = e.target;
    this.setState({
      [name]: value,
    });
  };

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.dataPart) !== JSON.stringify(this.props.dataPart)
    ) {
      this.setState({
        dataPart: this.props.dataPart,
      });
    }

    if (
      JSON.stringify(prevProps.dataComment) !==
      JSON.stringify(this.props.dataComment)
    ) {
      this.setState({
        dataComment: this.props.dataComment ? this.props.dataComment : "",
      });
    }

    if (
      (JSON.stringify(prevProps.dataAdministrative) !==
        JSON.stringify(this.props.dataAdministrative) ||
        JSON.stringify(prevProps.adsSelect) !==
          JSON.stringify(this.props.adsSelect) ||
        (JSON.stringify(prevProps.tabsId) !==
          JSON.stringify(this.props.tabsId) &&
          this.props.tabsId === 3)) &&
      this.props.dataAdministrative.empty
    ) {
      let tmpDataAdministrative = this.props.dataAdministrative
        ? this.props.dataAdministrative
        : {};

      Object.keys(this.props.dataAdministrative).map((key) => {
        if (!tmpDataAdministrative[key].name) {
          delete tmpDataAdministrative[key];
        }
        return null;
      });

      if (
        this.props.adsSelect &&
        this.props.adsSelect.length &&
        this.props.dataAdministrative.empty &&
        this.props.tabsId === 3
      ) {
        let costMaintenance = 0;
        if (
          getDifferenceYears(
            moment(this.props.dataIdentification["Date1MEC"][0], "DD/MM/YYYY"),
            moment(
              this.props.dataIdentification["Date_Sinistre"][0],
              "DD/MM/YYYY"
            )
          ) >= 1
        ) {
          costMaintenance =
            parseInt((this.props.vradeRef.averagePrice * 0.05) / 50) * 50 * -1;
        }

        tmpDataAdministrative["1-AD"] = {
          name: "Suivi d'entretien",
          option: "absent",
          value: costMaintenance,
          isDefault: true,
        };

        if (Object.keys(tmpDataAdministrative).length) {
          this.setState(
            {
              dataAdministrative: tmpDataAdministrative,
            },
            this.props.updateFolder("dataAdministrative", tmpDataAdministrative)
          );
        }
      }
    } else if (
      this.props.dataAdministrative &&
      JSON.stringify(this.props.dataAdministrative) !==
        JSON.stringify(this.state.dataAdministrative)
    ) {
      this.setState({
        dataAdministrative: this.props.dataAdministrative,
      });
    }
  }

  updateFolderComment = () => {
    this.props.updateFolder("dataComment", this.state.dataComment);
  };

  getParts = (type) => {
    let data = [];

    const parts = getParts(
      this.props.gender,
      this.props.dataIdentification && this.props.dataIdentification["Genre"]
        ? this.props.dataIdentification["Genre"][0]
        : null,
      this.props.dataIdentification &&
        this.props.dataIdentification["Categorie"]
        ? this.props.dataIdentification["Categorie"][0]
        : null
    );

    if (this.state.dataPart) {
      Object.keys(this.state.dataPart).map((key) => {
        if (parts.hasOwnProperty(key) && parts[key].type === type) {
          data.push(
            <Tr key={key}>
              <Td>{parts[key].name}</Td>
              <Td>
                {parts[key].options
                  ? parts[key].options[
                      this.state.dataPart[key].state
                        ? this.state.dataPart[key].state
                        : 0
                    ][0]
                  : this.state.dataPart[key].state}
              </Td>
              {!parseInt(this.props.extendDataPart[type]) ? (
                <Td>
                  {this.state.dataPart[key].value
                    ? this.state.dataPart[key].value
                    : 0}{" "}
                  €
                </Td>
              ) : (
                <Td />
              )}
            </Tr>
          );
        } else if (key.split("-")[0] === type) {
          data.push(
            <Tr key={key}>
              <Td>{this.state.dataPart[key].title}</Td>
              <Td>{this.state.dataPart[key].status}</Td>
              <Td>
                {this.state.dataPart[key].value
                  ? this.state.dataPart[key].value
                  : 0}{" "}
                €
              </Td>
            </Tr>
          );
        }

        return null;
      });
    }

    return data;
  };

  onDelete = (key) => {
    if (this.props.isEdit) {
      let tmpDataAdministrative = this.state.dataAdministrative;

      delete tmpDataAdministrative[key];

      this.props.updateFolder("dataAdministrative", tmpDataAdministrative);
    }
  };

  onUpdateValue = (key, value) => {
    if (this.props.isEdit) {
      let tmpDataAdministrative = this.state.dataAdministrative;

      tmpDataAdministrative[key].value = value;

      this.props.updateFolder("dataAdministrative", tmpDataAdministrative);
    }
  };

  onUpdateOption = (key, e) => {
    if (this.props.isEdit) {
      const { value } = e.target;

      let tmpDataAdministrative = this.state.dataAdministrative;

      tmpDataAdministrative[key].option = value;
      if (value === "absent") {
        tmpDataAdministrative[key].value = -150;
      } else {
        tmpDataAdministrative[key].value = 0;
      }

      this.props.updateFolder("dataAdministrative", tmpDataAdministrative);
    }
  };

  addDataAdministrative = () => {
    if (this.props.isEdit) {
      let tmpDataAdministrative = this.state.dataAdministrative;

      tmpDataAdministrative[Object.keys(tmpDataAdministrative).length + "AD"] =
        {
          name: "",
          option: "DEFAULT",
          value: 0,
        };

      this.setState({
        dataAdministrative: tmpDataAdministrative,
      });
    }
  };

  onChangeInput = (key, e, type) => {
    if (this.props.isEdit) {
      const { value } = e.target;

      let tmpDataAdministrative = this.state.dataAdministrative;
      if (type === 1) {
        tmpDataAdministrative[key].name = value;
      } else {
        tmpDataAdministrative[key].optionText = value;
      }

      this.setState({
        dataAdministrative: tmpDataAdministrative,
      });
    }
  };

  onBlurInput = () => {
    if (this.props.isEdit) {
      let tmpDataAdministrative = this.state.dataAdministrative;

      this.props.updateFolder("dataAdministrative", tmpDataAdministrative);
    }
  };

  onClickValidModalParts = (data, extendData) => {
    this.setState({ isModalParts: false }, () => {
      this.props.updateFolder("dataPart", data, () => {
        this.props.updateFolder("extendDataPart", extendData);
      });
    });
  };

  render() {
    const parts = getParts(
      this.props.gender,
      this.props.dataIdentification && this.props.dataIdentification["Genre"]
        ? this.props.dataIdentification["Genre"][0]
        : null,
      this.props.dataIdentification &&
        this.props.dataIdentification["Categorie"]
        ? this.props.dataIdentification["Categorie"][0]
        : null
    );

    let leftSideValue = 0;
    let rightSideValue = 0;
    let frontValue = 0;
    let rearValue = 0;
    let roofValue = 0;
    let interiorValue = 0;
    let fuelTankValue = 0;
    let exhaustValue = 0;
    let saddleValue = 0;

    let tyreValue = 0;
    let mechanicsValue = 0;
    let cockpitValue = 0;
    let bodyValue = 0;
    let administrativeValue = 0;

    if (this.state.dataPart) {
      Object.keys(this.state.dataPart).map((key) => {
        if (parts.hasOwnProperty(key) && this.state.dataPart[key].value) {
          switch (parts[key].main) {
            case "left_side":
              leftSideValue += this.state.dataPart[key].value;
              break;
            case "right_side":
              rightSideValue += this.state.dataPart[key].value;
              break;
            case "front":
              frontValue += this.state.dataPart[key].value;
              break;
            case "rear":
              rearValue += this.state.dataPart[key].value;
              break;
            case "roof":
              roofValue += this.state.dataPart[key].value;
              break;
            case "interior":
              interiorValue += this.state.dataPart[key].value;
              break;
            case "fuel_tank":
              fuelTankValue += this.state.dataPart[key].value;
              break;
            case "exhaust":
              exhaustValue += this.state.dataPart[key].value;
              break;
            case "saddle":
              saddleValue += this.state.dataPart[key].value;
              break;
            default:
              break;
          }

          switch (parts[key].type) {
            case "tyre":
              tyreValue += this.state.dataPart[key].value;
              break;
            case "mechanics":
              mechanicsValue += this.state.dataPart[key].value;
              break;
            case "cockpit":
              cockpitValue += this.state.dataPart[key].value;
              break;
            case "body":
              bodyValue += this.state.dataPart[key].value;
              break;
            default:
              break;
          }
        } else if (!key.includes("CP")) {
          switch (key.split("-")[0]) {
            case "tyre":
              tyreValue += this.state.dataPart[key].value;
              break;
            case "mechanics":
              mechanicsValue += this.state.dataPart[key].value;
              break;
            case "cockpit":
              cockpitValue += this.state.dataPart[key].value;
              break;
            case "body":
              bodyValue += this.state.dataPart[key].value;
              break;
            default:
              break;
          }
        }

        return null;
      });
    }

    tyreValue = parseInt(this.props.extendDataPart["tyre"]) || tyreValue;
    mechanicsValue =
      parseInt(this.props.extendDataPart["mechanics"]) || mechanicsValue;
    cockpitValue =
      parseInt(this.props.extendDataPart["cockpit"]) || cockpitValue;
    bodyValue = parseInt(this.props.extendDataPart["body"]) || bodyValue;

    if (this.state.dataAdministrative) {
      Object.keys(this.state.dataAdministrative).map((key) => {
        administrativeValue += this.state.dataAdministrative[key].value;
        return null;
      });
    }

    let totalValue =
      tyreValue +
      mechanicsValue +
      cockpitValue +
      bodyValue;

    this.totalValue = totalValue + administrativeValue;

    return (
      <>
        <Card
          title="Véhicule"
          className="mt-10 mt-20-xl w-100 overflow-hidden d-flex flex-column"
          classNameChildren="d-flex flex-column h-100"
        >
          <Row className="h-100">
            <Col
              xs={12}
              md={7}
              style={{ position: "relative", paddingBottom: "100px" }}
            >
              {!this.props.isPrint ? <div className="grid-line" /> : null}
              <div
                ref={(ref) => {
                  this.vehicleRef = ref;
                }}
                className="d-flex flex-column"
                style={{ position: "relative" }}
              >
                <Row>
                  <Col xs={12} lg={6}>
                    <div className="text-center">
                      <Wheel
                        isStandard={this.props.isEdit}
                        onClickItem={(name) => {
                          if (this.props.isEdit) {
                            this.setState({
                              isModalParts: true,
                              modalPartsName: name,
                            });
                          }
                        }}
                        tyreValue={tyreValue}
                      />
                    </div>
                  </Col>
                </Row>
                {this.props.gender === 0 ? (
                  <div className="text-center">
                    <Car
                      isStandard={this.props.isEdit}
                      leftSideValue={leftSideValue}
                      rightSideValue={rightSideValue}
                      frontValue={frontValue}
                      rearValue={rearValue}
                      roofValue={roofValue}
                      interiorValue={interiorValue}
                      onClickItem={(name) => {
                        if (this.props.isEdit) {
                          this.setState({
                            isModalParts: true,
                            modalPartsName: name,
                          });
                        }
                      }}
                    />
                  </div>
                ) : null}
                {this.props.gender === 1 ? (
                  <div className="text-center">
                    <Bike
                      isStandard={this.props.isEdit}
                      frontValue={frontValue}
                      fuelTankValue={fuelTankValue}
                      exhaustValue={exhaustValue}
                      tyreValue={tyreValue}
                      saddleValue={saddleValue}
                      onClickItem={(name) => {
                        if (this.props.isEdit) {
                          this.setState({
                            isModalParts: true,
                            modalPartsName: name,
                          });
                        }
                      }}
                    />
                  </div>
                ) : null}
                {this.props.gender === 2 ? (
                  <div className="text-center">
                    <Commercial
                      isStandard={this.props.isEdit}
                      leftSideValue={leftSideValue}
                      rightSideValue={rightSideValue}
                      frontValue={frontValue}
                      rearValue={rearValue}
                      roofValue={roofValue}
                      interiorValue={interiorValue}
                      onClickItem={(name) => {
                        if (this.props.isEdit) {
                          this.setState({
                            isModalParts: true,
                            modalPartsName: name,
                          });
                        }
                      }}
                    />
                  </div>
                ) : null}
                {this.props.gender === 3 ? (
                  <div className="text-center">
                    <Caravaning1
                      isStandard={this.props.isEdit}
                      leftSideValue={leftSideValue}
                      rightSideValue={rightSideValue}
                      frontValue={frontValue}
                      rearValue={rearValue}
                      roofValue={roofValue}
                      interiorValue={interiorValue}
                      onClickItem={(name) => {
                        if (this.props.isEdit) {
                          this.setState({
                            isModalParts: true,
                            modalPartsName: name,
                          });
                        }
                      }}
                    />
                  </div>
                ) : null}
                {this.props.gender === 4 ? (
                  <div className="text-center">
                    <Caravaning2
                      isStandard={this.props.isEdit}
                      leftSideValue={leftSideValue}
                      rightSideValue={rightSideValue}
                      frontValue={frontValue}
                      rearValue={rearValue}
                      roofValue={roofValue}
                      interiorValue={interiorValue}
                      onClickItem={(name) => {
                        if (this.props.isEdit) {
                          this.setState({
                            isModalParts: true,
                            modalPartsName: name,
                          });
                        }
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </Col>
            <Col
              xs={12}
              md={5}
              style={{
                position: "relative",
              }}
            >
              {!this.props.isPrint ? <div className="bg-vehicle" /> : null}
              <div className="overflow-y-auto" style={{ position: "relative" }}>
                <Table>
                  {tyreValue ? (
                    <>
                      <Thead>
                        <Tr>
                          <Th colSpan="2">Usure des pneus</Th>
                          <Th>{tyreValue} €</Th>
                        </Tr>
                      </Thead>
                      <Tbody>{this.getParts("tyre")}</Tbody>
                      <Tbody>
                        <Tr className="space" />
                      </Tbody>
                    </>
                  ) : null}
                  {bodyValue || cockpitValue ? (
                    <>
                      <Thead>
                        <Tr>
                          <Th colSpan="2">État général carrosserie</Th>
                          <Th>{bodyValue + cockpitValue} €</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {this.getParts("body")}
                        {this.getParts("cockpit")}
                      </Tbody>
                      <Tbody>
                        <Tr className="space" />
                      </Tbody>
                    </>
                  ) : null}
                  {mechanicsValue ? (
                    <>
                      <Thead>
                        <Tr>
                          <Th colSpan="2">État général mécanique</Th>
                          <Th>{mechanicsValue} €</Th>
                        </Tr>
                      </Thead>
                      <Tbody>{this.getParts("mechanics")}</Tbody>
                      <Tbody>
                        <Tr className="space" />
                      </Tbody>
                    </>
                  ) : null}
                  <Thead>
                    <Tr>
                      <Th colSpan="2">État général du véhicule</Th>
                      <Th>{totalValue} €</Th>
                    </Tr>
                  </Thead>
                </Table>
                {this.props.isSummary ? (
                  <div className="mt-20">
                    <Table>
                      {Object.keys(this.state.dataAdministrative).map((key) => (
                        <Fragment key={key}>
                          <Tbody>
                            <Tr>
                              <Td>
                                {this.state.dataAdministrative[key]
                                  .isDefault ? (
                                  this.state.dataAdministrative[key].name
                                ) : (
                                  <input
                                    placeholder="Nom"
                                    value={
                                      this.state.dataAdministrative[key].name
                                    }
                                    className="input-administrative"
                                    onChange={(e) =>
                                      this.onChangeInput(key, e, 1)
                                    }
                                    onBlur={this.onBlurInput}
                                  />
                                )}
                              </Td>
                              <Td>
                                {this.state.dataAdministrative[key]
                                  .isDefault ? (
                                  <select
                                    value={
                                      this.state.dataAdministrative[key].option
                                    }
                                    className="select-administrative"
                                    onChange={(e) =>
                                      this.onUpdateOption(key, e)
                                    }
                                  >
                                    <option value="DEFAULT">Présent</option>
                                    <option value="absent">Absent</option>
                                    <option value="nc">N.C.</option>
                                  </select>
                                ) : (
                                  <input
                                    placeholder="Nom"
                                    value={
                                      this.state.dataAdministrative[key]
                                        .optionText
                                    }
                                    className="input-administrative"
                                    onChange={(e) =>
                                      this.onChangeInput(key, e, 2)
                                    }
                                    onBlur={this.onBlurInput}
                                  />
                                )}
                              </Td>
                              <Td>
                                <div className="value-administrative">
                                  {!this.props.isPrint && this.props.isEdit ? (
                                    <button
                                      onClick={() =>
                                        this.onUpdateValue(
                                          key,
                                          this.state.dataAdministrative[key]
                                            .value - 50
                                        )
                                      }
                                    >
                                      -
                                    </button>
                                  ) : null}
                                  <span>
                                    {getPrice(
                                      this.state.dataAdministrative[key].value
                                    )}
                                  </span>
                                  {!this.props.isPrint && this.props.isEdit ? (
                                    <button
                                      onClick={() =>
                                        this.onUpdateValue(
                                          key,
                                          this.state.dataAdministrative[key]
                                            .value + 50
                                        )
                                      }
                                    >
                                      +
                                    </button>
                                  ) : null}
                                </div>
                              </Td>
                              <Td>
                                {this.props.isEdit ? (
                                  <div className="d-flex">
                                    <ButtonIcon
                                      className="p-0 m-auto"
                                      small
                                      style={{
                                        color: "var(--red)",
                                      }}
                                      onClick={() => {
                                        this.onDelete(key);
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faTrash} />
                                    </ButtonIcon>
                                  </div>
                                ) : null}
                              </Td>
                            </Tr>
                          </Tbody>
                          <Tbody>
                            <Tr className="space" />
                          </Tbody>
                        </Fragment>
                      ))}
                    </Table>
                    {!this.props.isPrint && this.props.isEdit ? (
                      <Button
                        text="Ajouter"
                        className="w-auto ml-auto mb-20"
                        onClick={this.addDataAdministrative}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    ) : null}
                    <Table>
                      <Thead>
                        <Tr>
                          <Th colSpan="2">État général administratif</Th>
                          <Th>{administrativeValue} €</Th>
                        </Tr>
                      </Thead>
                      <Tbody>{this.getParts("administrative")}</Tbody>
                    </Table>
                  </div>
                ) : null}

                {this.props.isEdit || this.state.dataComment ? (
                  <>
                    <div className="mt-10" />
                    <Table>
                      <Thead>
                        <Tr>
                          <Th colSpan="3" className="text-center">
                            Commentaire de l'expert
                          </Th>
                        </Tr>
                      </Thead>
                    </Table>
                  </>
                ) : null}
                {this.props.isEdit && !this.props.isPrint ? (
                  <FormTextarea
                    placeholder="Commentaire..."
                    rows={3}
                    name="dataComment"
                    value={this.state.dataComment ? this.state.dataComment : ""}
                    onChange={this.handleInputChange}
                    onBlur={this.updateFolderComment}
                  />
                ) : this.state.dataComment ? (
                  <p
                    style={{
                      margin: 0,
                      padding: "10px",
                      borderRadius: "10px",
                      backgroundColor: "var(--white)",
                    }}
                  >
                    {this.state.dataComment}
                  </p>
                ) : null}
              </div>
            </Col>
          </Row>
        </Card>
        <ModalParts
          dataIdentification={this.props.dataIdentification}
          show={this.state.isModalParts}
          name={this.state.modalPartsName}
          gender={this.props.gender}
          data={this.state.dataPart ? this.state.dataPart : {}}
          extendData={
            this.props.extendDataPart ? this.props.extendDataPart : {}
          }
          onValid={this.onClickValidModalParts}
          onClose={() =>
            this.setState({ isModalParts: false, modalPartsName: null })
          }
        />
      </>
    );
  }
}
