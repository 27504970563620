import React from "react";
import { Card, Signature } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

import ModalSignature from "./ModalSignature";

export default class DescriptiveStateSignature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalSignature: false,
      modalSignatureType: null,
    };
  }

  onAddSignature = (type) => {
    this.setState({
      isModalSignature: true,
      modalSignatureType: type,
    });
  };

  onRemoveSignature = (type) => {
    if (this.props.isEdit) {
      let tmpDataSignature = this.props.dataSignature;

      if (type === "expert") {
        tmpDataSignature.expert = undefined;
      } else {
        tmpDataSignature.repairman = undefined;
      }

      this.props.updateFolder("dataSignature", tmpDataSignature);
    }
  };

  onValidModalSignature = (image) => {
    if (this.props.isEdit) {
      let tmpDataSignature = this.props.dataSignature;

      if (this.state.modalSignatureType === "expert") {
        tmpDataSignature.expert = image;
      } else {
        tmpDataSignature.repairman = image;
      }

      this.setState(
        {
          isModalSignature: false,
          modalSignatureType: null,
        },
        this.props.updateFolder("dataSignature", tmpDataSignature)
      );
    }
  };

  render() {
    return (
      <>
        <Card title="Signature" className="mt-10 mt-20-xl">
          <Row>
            <Col xs={12} md={6} className="mb-20">
              <h3>Signature de l'expert</h3>
              <Signature
                onAdd={() => this.onAddSignature("expert")}
                onRemove={() => this.onRemoveSignature("expert")}
                signature={this.props.dataSignature.expert}
                disabled={this.props.isPrint || !this.props.isEdit}
              />
            </Col>
          </Row>
        </Card>
        <ModalSignature
          show={this.state.isModalSignature}
          type={this.state.modalSignatureType}
          onClose={() =>
            this.setState({
              isModalSignature: false,
              modalSignatureType: null,
            })
          }
          onValid={this.onValidModalSignature}
        />
      </>
    );
  }
}
