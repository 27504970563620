import axiosConfig from "../utils/axiosConfig";

const getFolders = (filters, callback) => {
  axiosConfig("home/get-folders", {
    data: filters,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getTour = (callback) => {
  axiosConfig("home/get-tour")
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const deleteFolder = (id, callback) => {
  axiosConfig("home/delete-folder", { data: { id } })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getStatistics = (callback) => {
  axiosConfig("home/get-statistics", {})
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const addFolder = (reference, isForce, callback) => {
  axiosConfig("home/add-folder", { data: { reference, isForce } })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

export { getFolders, getTour, getStatistics, deleteFolder, addFolder };
