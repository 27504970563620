import React from "react";

export default class Wheel extends React.Component {
  onClick = (e) => {
    this.props.onClickItem(e.target.getAttribute("name"));
  };

  getColor = (value) => {
    if (value > 0) {
      return this.props.isStandard ? "#bbe1fe" : "#bcf5d5";
    } else if (value < 0) {
      return this.props.isStandard ? "#bbe1fe" : "#ffb8b8";
    } else {
      return "#EFEFEF";
    }
  };

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 124 124"
        style={{ maxHeight: "110px", minHeight: "110px" }}
        className="pb-10"
      >
        <g>
          <g>
            <g
              onClick={this.onClick}
              name="tyre"
              fill={this.getColor(this.props.tyreValue)}
              className={this.props.isStandard ? "svg-hover" : ""}
            >
              <g transform="translate(55.374 55.644)" stroke="#707070">
                <circle
                  name="tyre"
                  cx={6.516}
                  cy={6.516}
                  r={6.516}
                  stroke="none"
                />
                <circle
                  name="tyre"
                  cx={6.516}
                  cy={6.516}
                  r={6.016}
                  fill="none"
                />
              </g>
              <g data-name="Polygone 1">
                <path
                  name="tyre"
                  d="M55.586 29.209h12.608c.493 0 .938.23 1.223.632.285.402.356.899.193 1.364l-6.304 18.011c-.26.74-.902 1.005-1.416 1.005-.515 0-1.157-.264-1.416-1.005L54.17 31.205a1.483 1.483 0 01.192-1.364c.285-.402.731-.632 1.224-.632z"
                />
                <path
                  name="tyre"
                  d="M61.89 49.72c.166 0 .726-.047.944-.669l6.304-18.012a.988.988 0 00-.128-.909.988.988 0 00-.816-.421H55.586a.988.988 0 00-.816.421.988.988 0 00-.128.91l6.304 18.011c.218.622.777.67.944.67m0 1c-.788 0-1.575-.447-1.888-1.34L53.698 31.37a2 2 0 011.888-2.661h12.608a2 2 0 011.888 2.66l-6.304 18.013c-.313.892-1.1 1.339-1.888 1.339z"
                  fill="#707070"
                />
              </g>
              <g data-name="Polygone 2">
                <path
                  name="tyre"
                  d="M68.162 95.112H55.618c-.496 0-.943-.233-1.228-.639a1.482 1.482 0 01-.182-1.372L60.48 75.78c.264-.73.901-.99 1.41-.99.509 0 1.146.26 1.41.99l6.272 17.322c.17.467.103.967-.182 1.372a1.482 1.482 0 01-1.228.64z"
                />
                <path
                  name="tyre"
                  d="M61.89 75.29c-.164 0-.719.047-.94.66l-6.272 17.322a.988.988 0 00.122.914c.19.27.488.426.818.426h12.544c.33 0 .629-.155.819-.426a.988.988 0 00.121-.914L62.83 75.949c-.221-.612-.775-.66-.94-.66m0-1c.781 0 1.562.44 1.88 1.32l6.273 17.322a2 2 0 01-1.881 2.681H55.618a2 2 0 01-1.88-2.68l6.272-17.323c.318-.88 1.099-1.32 1.88-1.32z"
                  fill="#707070"
                />
              </g>
              <g data-name="Polygone 3">
                <path
                  name="tyre"
                  d="M94.842 55.888v12.544c0 .496-.233.943-.639 1.228a1.482 1.482 0 01-1.372.182L75.51 63.57c-.73-.264-.99-.901-.99-1.41 0-.509.26-1.146.99-1.41l17.322-6.272a1.482 1.482 0 011.372.182c.406.285.64.732.64 1.228z"
                />
                <path
                  name="tyre"
                  d="M75.02 62.16c0 .164.047.719.66.94l17.322 6.272c.31.113.644.068.914-.122a.988.988 0 00.426-.818V55.888a.988.988 0 00-.426-.819.988.988 0 00-.914-.121L75.679 61.22c-.612.221-.66.775-.66.94m-1 0c0-.781.44-1.562 1.32-1.88l17.322-6.273a2 2 0 012.681 1.881v12.544a2 2 0 01-2.68 1.88L75.338 64.04a1.967 1.967 0 01-1.32-1.88z"
                  fill="#707070"
                />
              </g>
              <g data-name="Polygone 4">
                <path
                  name="tyre"
                  d="M28.939 68.464V55.856c0-.493.23-.938.632-1.223a1.483 1.483 0 011.364-.193l18.011 6.304c.74.26 1.005.902 1.005 1.416 0 .515-.264 1.157-1.005 1.416L30.935 69.88a1.483 1.483 0 01-1.364-.192 1.483 1.483 0 01-.632-1.224z"
                />
                <path
                  name="tyre"
                  d="M49.45 62.16c0-.166-.047-.726-.669-.944L30.77 54.912a.988.988 0 00-.909.128.988.988 0 00-.421.816v12.608c0 .329.154.626.421.816.268.19.6.237.91.128l18.011-6.304c.622-.218.67-.777.67-.944m1 0c0 .788-.447 1.575-1.34 1.888L31.1 70.352a2 2 0 01-2.661-1.888V55.856a2 2 0 012.66-1.888l18.013 6.304c.892.313 1.339 1.1 1.339 1.888z"
                  fill="#707070"
                />
              </g>
              <g data-name="Polygone 5">
                <path
                  name="tyre"
                  d="M42.782 89.945l-8.84-8.84a1.483 1.483 0 01-.417-1.311c.082-.486.384-.887.828-1.1l17.046-8.208c.707-.34 1.348-.073 1.711.291.364.364.632 1.004.291 1.711l-8.207 17.047a1.483 1.483 0 01-1.1.828 1.483 1.483 0 01-1.312-.418z"
                />
                <path
                  name="tyre"
                  d="M52.757 71.13c-.118-.117-.548-.479-1.141-.193L34.57 79.144a.988.988 0 00-.552.734.988.988 0 00.278.874l8.84 8.839c.231.232.55.334.874.279a.988.988 0 00.733-.552l8.208-17.047c.285-.593-.076-1.023-.194-1.14m.707-.708c.557.557.798 1.43.388 2.282l-8.208 17.046a2 2 0 01-3.216.547L33.59 81.46a2 2 0 01.547-3.216l17.046-8.207c.852-.41 1.725-.17 2.282.387z"
                  fill="#707070"
                />
              </g>
              <g data-name="Polygone 6">
                <path
                  name="tyre"
                  d="M80.879 34.4l8.987 8.687c.354.342.515.818.44 1.305-.074.487-.369.893-.809 1.114L72.593 54c-.701.353-1.346.096-1.716-.261-.37-.358-.649-.994-.32-1.707l7.917-17.182a1.483 1.483 0 011.086-.847c.484-.09.964.053 1.319.396z"
                />
                <path
                  name="tyre"
                  d="M71.224 53.38c.12.116.556.47 1.144.175l16.904-8.496a.988.988 0 00.54-.743.988.988 0 00-.294-.87l-8.987-8.687a.988.988 0 00-.88-.264.988.988 0 00-.723.565L71.01 52.243c-.276.598.093 1.021.213 1.137m-.695.72c-.566-.548-.822-1.417-.426-2.276l7.917-17.183a2 2 0 013.206-.6l8.987 8.687a2 2 0 01-.491 3.225l-16.905 8.495c-.845.425-1.722.199-2.288-.349z"
                  fill="#707070"
                />
              </g>
              <g data-name="Polygone 7">
                <path
                  name="tyre"
                  d="M90.194 80.587l-8.84 8.84c-.348.347-.826.5-1.311.417a1.483 1.483 0 01-1.1-.828L70.735 71.97c-.34-.707-.073-1.348.291-1.711.364-.364 1.004-.632 1.711-.291l17.047 8.207c.443.214.745.615.828 1.1.082.486-.07.964-.418 1.312z"
                />
                <path
                  name="tyre"
                  d="M71.38 70.612c-.118.118-.48.548-.194 1.141L79.393 88.8c.143.296.41.497.734.552a.988.988 0 00.874-.278l8.839-8.84a.988.988 0 00.279-.874.988.988 0 00-.552-.733L72.52 70.418c-.593-.285-1.023.076-1.14.194m-.708-.707c.557-.557 1.43-.798 2.282-.388L90 77.725a2 2 0 01.547 3.216l-8.839 8.839a2 2 0 01-3.216-.547l-8.207-17.046c-.41-.852-.17-1.725.387-2.282z"
                  fill="#707070"
                />
              </g>
              <g data-name="Polygone 8">
                <path
                  name="tyre"
                  d="M34.461 43.31l8.84-8.84c.348-.347.826-.5 1.311-.417.486.082.887.384 1.1.828l8.208 17.046c.34.707.073 1.348-.291 1.711-.364.364-1.004.632-1.711.291L34.87 45.722a1.483 1.483 0 01-.828-1.1c-.082-.486.07-.964.418-1.312z"
                />
                <path
                  name="tyre"
                  d="M53.275 53.285c.118-.118.48-.548.194-1.141l-8.207-17.046a.988.988 0 00-.734-.552.988.988 0 00-.874.278l-8.839 8.84a.988.988 0 00-.279.874.988.988 0 00.552.733l17.047 8.208c.593.285 1.023-.076 1.14-.194m.708.707c-.557.557-1.43.798-2.282.388l-17.046-8.208a2 2 0 01-.547-3.216l8.839-8.839a2 2 0 013.216.547L54.37 51.71c.41.852.17 1.725-.387 2.282z"
                  fill="#707070"
                />
              </g>
            </g>
            <g
              onClick={this.onClick}
              name="tyre"
              fill={this.getColor(this.props.tyreValue)}
              className={this.props.isStandard ? "svg-hover" : ""}
            >
              <path
                name="tyre"
                d="M62 123.5c-4.173 0-8.343-.42-12.395-1.25a61.19 61.19 0 01-21.99-9.253 61.94 61.94 0 01-9.102-7.51 61.944 61.944 0 01-7.51-9.102 61.185 61.185 0 01-9.253-21.99A61.955 61.955 0 01.5 62c0-4.173.42-8.343 1.25-12.395a61.185 61.185 0 019.253-21.99 61.942 61.942 0 017.51-9.102 61.942 61.942 0 019.102-7.51 61.185 61.185 0 0121.99-9.253A61.956 61.956 0 0162 .5c4.173 0 8.343.42 12.395 1.25a61.185 61.185 0 0121.99 9.253 61.944 61.944 0 019.102 7.51 61.94 61.94 0 017.51 9.102 61.19 61.19 0 019.254 21.99A61.958 61.958 0 01123.5 62c0 4.173-.42 8.343-1.25 12.395a61.19 61.19 0 01-9.253 21.99 61.943 61.943 0 01-7.51 9.102 61.943 61.943 0 01-9.102 7.51 61.19 61.19 0 01-21.99 9.254A61.957 61.957 0 0162 123.5zm0-97.895c-20.068 0-36.395 16.327-36.395 36.395S41.932 98.395 62 98.395 98.395 82.068 98.395 62 82.068 25.605 62 25.605z"
              />
              <path
                name="tyre"
                d="M62 123c4.14 0 8.276-.417 12.294-1.24a60.688 60.688 0 0021.81-9.177 61.441 61.441 0 009.03-7.45 61.441 61.441 0 007.449-9.028 60.688 60.688 0 009.178-21.81A61.457 61.457 0 00123 62c0-4.14-.417-8.276-1.24-12.295a60.69 60.69 0 00-9.177-21.81 61.442 61.442 0 00-7.45-9.029 61.443 61.443 0 00-9.028-7.449 60.683 60.683 0 00-21.81-9.178A61.454 61.454 0 0062 1c-4.14 0-8.276.417-12.295 1.24a60.683 60.683 0 00-21.81 9.177 61.442 61.442 0 00-9.029 7.45 61.442 61.442 0 00-7.449 9.028 60.683 60.683 0 00-9.178 21.81A61.454 61.454 0 001 62c0 4.14.417 8.276 1.24 12.294a60.684 60.684 0 009.177 21.81 61.441 61.441 0 007.45 9.03 61.441 61.441 0 009.028 7.449 60.69 60.69 0 0021.81 9.178A61.457 61.457 0 0062 123m0-97.895c20.344 0 36.895 16.551 36.895 36.895 0 20.344-16.551 36.895-36.895 36.895-20.344 0-36.895-16.551-36.895-36.895 0-20.344 16.551-36.895 36.895-36.895M62 124a62.46 62.46 0 01-12.495-1.26 61.692 61.692 0 01-22.17-9.329 62.443 62.443 0 01-9.176-7.57 62.444 62.444 0 01-7.57-9.176 61.686 61.686 0 01-9.329-22.17A62.457 62.457 0 010 62c0-4.207.424-8.41 1.26-12.495a61.687 61.687 0 019.329-22.17 62.444 62.444 0 017.57-9.176 62.444 62.444 0 019.176-7.57 61.687 61.687 0 0122.17-9.329A62.457 62.457 0 0162 0c4.206 0 8.41.424 12.495 1.26a61.686 61.686 0 0122.17 9.329 62.444 62.444 0 019.175 7.57 62.443 62.443 0 017.571 9.176 61.692 61.692 0 019.33 22.17A62.46 62.46 0 01124 62c0 4.206-.424 8.41-1.26 12.495a61.691 61.691 0 01-9.329 22.17 62.443 62.443 0 01-7.57 9.175 62.443 62.443 0 01-9.176 7.571 61.691 61.691 0 01-22.17 9.33A62.459 62.459 0 0162 124zm0-97.895c-19.792 0-35.895 16.103-35.895 35.895S42.208 97.895 62 97.895 97.895 81.792 97.895 62 81.792 26.105 62 26.105z"
                fill="#707070"
              />
            </g>
            <g
              transform="translate(5 5)"
              fill="none"
              stroke="#707070"
              strokeLinecap="round"
              strokeDasharray="4 12"
            >
              <circle name="tyre" cx={57} cy={57} r={57} stroke="none" />
              <circle name="tyre" cx={57} cy={57} r={56.5} />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
