import React from "react";
import { faFile, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RCamera } from "react-camera-media-stream";

export default class PartsPicture extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCamera: false,
    };
  }

  imageToDataUri = (picture, callback) => {
    var img = new Image();

    img.onload = () => {
      var canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d");

      let height = 1280;
      let width = 1280;

      if (canvas.width > canvas.height) {
        height = (1280 * canvas.height) / canvas.width;
      } else {
        width = (1280 * canvas.width) / canvas.height;
      }

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);
      callback(canvas.toDataURL("image/jpeg", 0.7));
    };
    img.onerror = () => {
      callback(picture);
    };
    img.src = picture;
  };

  render() {
    return (
      <>
        <div
          className="parts-picture"
          onClick={() => {
            if (
              window.innerWidth < 768 &&
              !this.props.picture &&
              window.confirm("Voulez-vous ouvrir l'appareil photo ?")
            ) {
              this.setState({
                isCamera: true,
              });
            } else if (!this.props.picture) {
              if (this.refInput) {
                this.refInput.click();
              }
            } else if (this.props.picture) {
              this.props.onTakePictureClick(null, null);
            }
          }}
        >
          <div className="d-flex w-100">
            {this.props.picture ? (
              <>
                {this.props.mime &&
                typeof this.props.mime === "string" &&
                this.props.mime.includes("image") ? (
                  <img
                    style={{ maxHeight: "46px", maxWidth: "100%" }}
                    src={this.props.picture}
                    alt="part"
                  />
                ) : (
                  <span className="m-auto blue">
                    <FontAwesomeIcon icon={faFile} />
                  </span>
                )}
                <div
                  style={{
                    margin: "auto",
                    marginRight: "10px",
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </div>
              </>
            ) : (
              <div className="m-auto">
                <FontAwesomeIcon icon={faPlus} />
              </div>
            )}
          </div>
        </div>
        <input
          ref={(ref) => (this.refInput = ref)}
          type="file"
          style={{ display: "none" }}
          onChange={(e) => {
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = () => {
              if (e.target.files[0].type.includes("image")) {
                this.imageToDataUri(reader.result, (result) => {
                  this.props.onTakePictureClick(result, e.target.files[0].type);
                });
              } else {
                this.props.onTakePictureClick(
                  reader.result,
                  e.target.files[0].type
                );
              }
            };
            reader.onerror = (error) => {
              console.log("Error: ", error);
            };
          }}
        />
        {this.state.isCamera ? (
          <RCamera
            onTakePicture={(picture) => {
              this.setState(
                {
                  isCamera: false,
                },
                () => this.props.onTakePictureClick(picture, "image/jpeg")
              );
            }}
            onClose={() => this.setState({ isCamera: false })}
            isConfirm={true}
            isFullscreen={false}
            isFixButton={true}
            max={1600}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}
