/*global chrome*/
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsUp,
  faThumbsDown,
  faPlus,
  faMinusCircle,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faArrowCircleUp,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Status,
  ButtonIcon,
  Button,
  Pagination,
  Loader,
  Modal,
} from "ui-kit-ck-consultant";
import moment from "moment";

import ModalImage from "./ModalImage";
import ModalData from "./ModalData";
import ModalAddAd from "./ModalAddAd";

import { getPrice, getInt, getIcon } from "../../../utils/general";
import { getRegionFromDepartment } from "../../../utils/region";

import { addAd, getAd } from "../../../requests/marketSearch";

import frFlag from "../../../images/flag/fr.svg";
import deFlag from "../../../images/flag/de.svg";
import beFlag from "../../../images/flag/be.svg";
import luFlag from "../../../images/flag/lu.svg";
import nlFlag from "../../../images/flag/nl.svg";
import itFlag from "../../../images/flag/it.svg";

export default class Ads extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      order: {},
      isModalImage: false,
      modalImageSrc: null,
      isModalData: false,
      modalDataIdx: null,
      ads: [],
      isModalAddAd: false,
      isModalExtension: false,
    };

    this.sortRef = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];
  }

  extensionScript = (event) => {
    if (event.data.type === "ads") {
      console.log(event.data);
      this.setState(
        {
          isLoad: true,
        },
        async () => {
          const keys = Object.keys(event.data.ads);
          for (let index = 0; index < keys.length; index++) {
            const ad = event.data.ads[keys[index]];
            console.log(index);

            await new Promise((resolve) =>
              addAd(
                {
                  url: ad.url,
                  title: ad.title,
                  image: ad.image,
                  brand: ad.brand,
                  model: ad.model,
                  mileage: ad.mileage,
                  year: moment(ad.date_circulation, "YYYY-MM-DD").format(
                    "YYYY"
                  ),
                  date_circulation: ad.date_circulation,
                  date_index: ad.date_index,
                  zipcode: ad.zipcode,
                  gearbox: ad.gearbox,
                  fuel: ad.fuel,
                  price: ad.price,
                  description: ad.description,
                  gender: ad.gender,
                  country: ad.country,
                },
                (result) => {
                  if (result.success) {
                    this.onClickupdateStatusAd(null, true, result.id, () =>
                      resolve(true)
                    );
                  } else {
                    resolve(false);
                  }
                }
              )
            );
            chrome.runtime.sendMessage("hldjeocakaibnlnkcpmhjgaaihbjdpng", {
              type: "response",
            });
          }

          this.setState({ isLoad: false });
        }
      );
    }
  };

  componentDidMount() {
    console.log("init");
    window.addEventListener("message", this.extensionScript);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.extensionScript);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.ads) !== JSON.stringify(prevProps.ads)) {
      this.setState({
        ads: this.props.ads ? this.props.ads : [],
      });
    }
  }

  handleSortClick = (desc, column) => {
    this.sortRef.map((elem, idx) => {
      if (idx !== column) {
        this.sortRef[idx].current.setState({ desc: false, sort: false });
      }
      return null;
    });

    let order = { name: this.sortRef[column].current.props.nameSort, desc };

    this.setState(
      {
        order,
      },
      () => {
        if (this.props.isPagination) {
          this.props.onClickSearch();
        }
      }
    );
  };

  onClickOpenModalData = (e, idx) => {
    if (
      !e.target.matches(".not-click") &&
      !e.target.parentNode.matches(".not-click") &&
      !e.target.parentNode.parentNode.matches(".not-click") &&
      !e.target.parentNode.parentNode.parentNode.matches(".not-click") &&
      !e.target.parentNode.parentNode.parentNode.parentNode.matches(
        ".not-click"
      ) &&
      !e.target.parentNode.parentNode.parentNode.parentNode.parentNode.matches(
        ".not-click"
      )
    ) {
      this.setState(
        {
          isLoad: true,
        },
        () => {
          getAd(this.state.ads[idx].id, (result) => {
            if (result.success) {
              let tmpAds = this.state.ads;

              tmpAds[idx] = { ...tmpAds[idx], ...result.data };

              this.setState({
                isLoad: false,
                isModalData: true,
                modalDataIdx: idx,
                ads: tmpAds,
              });
            } else {
              this.setState(
                {
                  isLoad: false,
                },
                () => {
                  alert("Une erreur est survenue");
                }
              );
            }
          });
        }
      );
    }
  };

  updateFolderSelect = (idx, data) => {
    let tmpDataSelect = JSON.parse(JSON.stringify(this.props.dataSelect));

    if (tmpDataSelect.hasOwnProperty(this.state.ads[idx].id)) {
      tmpDataSelect[this.state.ads[idx].id] = {
        ...tmpDataSelect[this.state.ads[idx].id],
        ...data,
      };
    } else {
      tmpDataSelect[this.state.ads[idx].id] = data;
    }

    this.props.updateFolder("dataSelect", tmpDataSelect);
  };

  onClickupdateStatusAd = (idx, status, id = null, callback = () => {}) => {
    let tmpDataSelect = JSON.parse(JSON.stringify(this.props.dataSelect));

    if (tmpDataSelect.hasOwnProperty(id ? id : this.state.ads[idx].id)) {
      tmpDataSelect[this.state.ads[idx].id].status = status;
      if (status === true) {
        tmpDataSelect[this.state.ads[idx].id].date = moment();
      }
    } else {
      tmpDataSelect[id ? id : this.state.ads[idx].id] = {
        status,
        date: status === true ? moment() : undefined,
      };
    }

    this.props.updateFolder("dataSelect", tmpDataSelect, () => {
      callback();
      if (id) {
        this.props.onChangeTab();
      }
    });
  };

  getColorAd = (idx) => {
    if (this.props.dataSelect[this.state.ads[idx].id]) {
      if (this.props.dataSelect[this.state.ads[idx].id].status === true) {
        return "bg-green-light";
      } else if (
        this.props.dataSelect[this.state.ads[idx].id].status === false
      ) {
        return "bg-red-light";
      }
    } else {
      return "";
    }
  };

  getFlag = (country) => {
    if (country === "FR") {
      return frFlag;
    } else if (country === "DE") {
      return deFlag;
    } else if (country === "BE") {
      return beFlag;
    } else if (country === "LU") {
      return luFlag;
    } else if (country === "NL") {
      return nlFlag;
    } else if (country === "IT") {
      return itFlag;
    }
  };

  render() {
    return (
      <>
        <Card
          title="Annonces"
          className="mt-10 mt-20-xl"
          action={
            <div className="d-flex">
              <Button
                text="Extension récupération annonces"
                className="mr-10"
                onClick={() =>
                  this.setState({
                    isModalExtension: true,
                  })
                }
              >
                <FontAwesomeIcon icon={faDownload} />
              </Button>
              <Button
                text="Ajouter une annonce manuellement"
                onClick={() =>
                  this.setState({
                    isModalAddAd: true,
                  })
                }
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          }
        >
          <Table>
            <Thead>
              <Tr>
                <Th>Image</Th>
                <Th>Titre</Th>
                <Th
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 0)}
                  ref={this.sortRef[0]}
                  nameSort="mileage"
                >
                  Kilométrage
                </Th>
                <Th
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 1)}
                  ref={this.sortRef[1]}
                  nameSort="date_circulation"
                >
                  Année
                </Th>
                <Th
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 2)}
                  ref={this.sortRef[2]}
                  nameSort="price"
                >
                  Prix
                </Th>
                <Th>Pays</Th>
                <Th>Source</Th>
                <Th
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 3)}
                  ref={this.sortRef[3]}
                  nameSort="date"
                >
                  Date
                </Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {this.state.ads.map((element, idx) => {
                if (
                  !this.props.filtersRef ||
                  (this.props.filtersRef &&
                    (this.props.filtersRef.state.isAllStatus ||
                      element.status === 0))
                ) {
                  return (
                    <Tr
                      key={idx}
                      className="cursor-pointer"
                      onClick={(e) => this.onClickOpenModalData(e, idx)}
                    >
                      <Td
                        style={{
                          position: "relative",
                          overflow: "hidden",
                          padding: "0 !important",
                        }}
                        className={this.getColorAd(idx)}
                      >
                        {element.image ? (
                          <img
                            onError={() => {
                              if (
                                element.image &&
                                element.source !== "evalcar"
                              ) {
                                this.props.onClickDisableAd(idx);
                              }
                            }}
                            className="not-click cursor-pointer"
                            style={{
                              position: "absolute",
                              width: " 100%",
                              top: "0",
                              bottom: "0",
                              left: " 50%",
                              transform: "translateX(-50%)",
                            }}
                            src={element.image}
                            alt="car"
                            onClick={() =>
                              this.setState({
                                isModalImage: true,
                                modalImageSrc: element.image,
                              })
                            }
                          />
                        ) : null}
                      </Td>
                      <Td className={this.getColorAd(idx)}>
                        <div className="d-flex">
                          {element.status ? (
                            <Status
                              text="désactivée"
                              className="mt-auto mb-auto bg-orange"
                              style={{ marginRight: "6px" }}
                            />
                          ) : null}{" "}
                          {element.type ? (
                            <Status
                              text="pro"
                              className="mt-auto mb-auto"
                              style={{ marginRight: "6px" }}
                            />
                          ) : null}
                          <span
                            style={{ display: "inline-block" }}
                            className="mt-auto mb-auto"
                          >
                            {element.title}
                          </span>
                        </div>
                      </Td>
                      <Td className={`nowrap ${this.getColorAd(idx)}`}>
                        {getInt(element.mileage)} km
                      </Td>
                      <Td className={`nowrap ${this.getColorAd(idx)}`}>
                        {element.date_circulation}
                      </Td>
                      <Td className={`nowrap ${this.getColorAd(idx)}`}>
                        {getPrice(element.price)}
                      </Td>
                      <Td className={`nowrap ${this.getColorAd(idx)}`}>
                        <div className="d-flex">
                          <img
                            className="m-auto"
                            height={18}
                            src={this.getFlag(element.country)}
                            alt={`flag-${element.country}`}
                            style={{ borderRadius: "6px" }}
                          />
                        </div>
                      </Td>
                      <Td className={`nowrap ${this.getColorAd(idx)}`}>
                        {!element.status ? (
                          <div className="d-flex">
                            <img
                              className="not-click cursor-pointer m-auto"
                              onClick={() => window.open(element.url, "_blank")}
                              alt="icon"
                              height={24}
                              src={getIcon(element.source, element.url)}
                              style={{ borderRadius: "6px" }}
                            />
                          </div>
                        ) : null}
                      </Td>
                      <Td className={`nowrap ${this.getColorAd(idx)}`}>
                        {element.date}
                      </Td>
                      <Td className={`nowrap ${this.getColorAd(idx)}`}>
                        <ButtonIcon
                          className="not-click green"
                          small
                          onClick={() => this.onClickupdateStatusAd(idx, true)}
                        >
                          <FontAwesomeIcon icon={faThumbsUp} />
                        </ButtonIcon>
                        <ButtonIcon
                          className="not-click red"
                          small
                          onClick={() => this.onClickupdateStatusAd(idx, false)}
                        >
                          <FontAwesomeIcon icon={faThumbsDown} />
                        </ButtonIcon>
                        {!element.status ? (
                          <ButtonIcon
                            className="not-click orange"
                            small
                            onClick={() => this.props.onClickDisableAd(idx)}
                          >
                            <FontAwesomeIcon icon={faMinusCircle} />
                          </ButtonIcon>
                        ) : null}
                        {element.status === 2 ? (
                          <ButtonIcon
                            className="not-click green"
                            small
                            onClick={() => this.props.onClickEnableAd(idx)}
                          >
                            <FontAwesomeIcon icon={faArrowCircleUp} />
                          </ButtonIcon>
                        ) : null}
                      </Td>
                    </Tr>
                  );
                } else {
                  return null;
                }
              })}
            </Tbody>
          </Table>
          {this.props.isPagination ? (
            <Pagination
              className="mt-10"
              activePage={this.props.activePage}
              itemsCountPerPage={this.props.tabLength}
              totalItemsCount={this.props.total}
              pageRangeDisplayed={6}
              onChange={this.props.handlePageChange.bind(this)}
              firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
              lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
              prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
              nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
            />
          ) : null}
        </Card>
        <ModalImage
          show={this.state.isModalImage}
          src={this.state.modalImageSrc}
          onClose={() =>
            this.setState({
              isModalImage: false,
              modalImageSrc: null,
            })
          }
        />
        <ModalData
          gender={this.props.gender}
          show={this.state.isModalData}
          ad={
            this.state.ads &&
            this.state.ads.hasOwnProperty(this.state.modalDataIdx)
              ? this.state.ads[this.state.modalDataIdx]
              : {}
          }
          select={
            this.props.dataSelect &&
            this.state.ads &&
            this.state.ads.hasOwnProperty(this.state.modalDataIdx)
              ? this.props.dataSelect[
                  this.state.ads[this.state.modalDataIdx].id
                ]
              : {}
          }
          dataSelect={this.props.dataSelect}
          updateFolderSelect={this.updateFolderSelect}
          idx={this.state.modalDataIdx}
          onClickDisableAd={this.props.onClickDisableAd}
          onClickEnableAd={this.props.onClickEnableAd}
          onClickAddAd={(idx) => this.onClickupdateStatusAd(idx, true)}
          onClickRemoveAd={(idx) => this.onClickupdateStatusAd(idx, false)}
          onClose={() =>
            this.setState({
              isModalData: false,
            })
          }
        />
        <ModalAddAd
          gender={this.props.gender}
          show={this.state.isModalAddAd}
          onClose={(id) =>
            this.setState(
              {
                isModalAddAd: false,
              },
              () => {
                if (id) {
                  this.onClickupdateStatusAd(null, true, id);
                }
              }
            )
          }
        />
        <Modal
          show={this.state.isModalExtension}
          title="Extension récupération annonces"
          onClose={() => this.setState({ isModalExtension: false })}
        >
          <Button
            text="Télécharger l'extension"
            onClick={() => {
              window
                .open(
                  "https://chrome.google.com/webstore/detail/maitrex/hldjeocakaibnlnkcpmhjgaaihbjdpng",
                  "_blank"
                )
                .focus();
            }}
          >
            <FontAwesomeIcon icon={faDownload} />
          </Button>
        </Modal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
