import React, { createRef } from "react";
import {
  Nav,
  TabsCustomArrow,
  TabsCustomArrowHeader,
  TabsCustomArrowMenu,
  TabsCustomArrowBody,
  TabsCustomArrowItem,
  Loader,
  FloatActionButton,
  FloatActionButtonItem,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faFlag, faPrint } from "@fortawesome/free-solid-svg-icons";

import Save from "../../components/folder/Save";
import AlertSource from "../../components/folder/AlertSource";
import Identification from "./folder/Identification";
import DescriptiveState from "./folder/DescriptiveState";
import MarketSearch from "./folder/MarketSearch";
import Summary from "./folder/Summary";

import {
  getFolder,
  updateFolder,
  updateHideImage,
  close,
  print,
  updateStatusFolder,
} from "../../requests/folder";

import {
  getGender,
  getFuel,
  checkObject,
} from "../../utils/general";
import { getRegionFromDepartment } from "../../utils/region";

import AuthContext from "../../context/AuthContext";

export default class Folder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      save: null,
      isLoad: true,
      reference: null,
      date: null,
      gender: null,
      marketSearchStatus: null,
      descriptiveStateStatus: null,
      // START - data
      dataIdentification: {},
      dataEquipment: {},
      dataPart: {},
      extendDataPart: {},
      dataComment: {},
      dataAdministrative: null,
      dataGuarding: {},
      dataSignature: {},
      dataSelect: {},
      dataSummary: {},
      dataFilters: null,
      dataHistovec: {},
      hideImage: 0,
      // END - data
      // START - tabs
      tabsId: 0,
      tabsIdArray: [
        { disabled: false, title: "1. Données du véhicule" },
        { disabled: true, title: "2. État descriptif du véhicule" },
        { disabled: true, title: "3. Recherche de véhicules" },
        { disabled: true, title: "4. Étude de marché" },
      ],
      // END - tabs
    };

    this.identificationCMPRef = createRef();
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.getFolder();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(this.state.dataIdentification) !==
      JSON.stringify(prevState.dataIdentification)
    ) {
      this.setDataFilters();
      this.onUpdateStatusSave(null, () => {});
    }
  }

  getFolder = () => {
    getFolder(this.props.match.params.id, (result) => {
      if (result.success) {
        this.setState(
          {
            isLoad: false,
            reference: result.data.reference,
            date: result.data.date,
            marketSearchStatus: result.data.market_search_status,
            descriptiveStateStatus: result.data.descriptive_state_status,
            dataIdentification: checkObject(result.data.data_identification),
            dataEquipment: checkObject(result.data.data_equipment),
            dataPart: checkObject(result.data.data_part),
            extendDataPart: checkObject(result.data.extend_data_part),
            dataComment: result.data.data_comment,
            dataAdministrative: result.data.data_administrative
              ? checkObject(result.data.data_administrative)
              : { empty: true },
            dataGuarding: checkObject(result.data.data_guarding),
            dataSignature: checkObject(result.data.data_signature),
            dataSelect: checkObject(result.data.data_select),
            dataSummary: checkObject(result.data.data_summary),
            dataHistovec: checkObject(result.data.data_histovec),
            hideImage: result.data.hide_image,
          },
          () => {
            /* START - set default user signature */
            let tmpDataSignature = this.state.dataSignature;
            let isUpdate = false;

            if (
              tmpDataSignature &&
              !tmpDataSignature.expert &&
              this.context.signature
            ) {
              tmpDataSignature.expert = this.context.signature;
              isUpdate = true;
            } else if (!tmpDataSignature && this.context.signature) {
              tmpDataSignature = {
                expert: this.context.signature,
              };
              isUpdate = true;
            }

            if (isUpdate && result.data.descriptive_state_status !== 2) {
              this.updateFolder("dataSignature", tmpDataSignature);
            }
            /* END - set default user signature */
          }
        );
      } else {
        alert("Une erreur est survenue");
        this.props.history.push("/home");
      }
    });
  };

  setDataFilters = () => {
    let tmpDataFilters = null;
    const dataIdentification = this.state.dataIdentification;
    let tmpGender = null;
    if (dataIdentification && dataIdentification["Constructeur"]) {
      tmpDataFilters = {
        brand: dataIdentification["Constructeur"][0],
        model: dataIdentification["Modele"][0],
        search: dataIdentification["Finition"]
          ? dataIdentification["Finition"][0]
          : null,
        fuel: getFuel(dataIdentification["Energie"][0]),
        year: dataIdentification["Annee_Modele"][0],
        mileage: dataIdentification["Kms"][0],
        region: dataIdentification["Code_Postal"]
          ? getRegionFromDepartment(
              dataIdentification["Code_Postal"][0]
                ? dataIdentification["Code_Postal"][0].substring(0, 2)
                : null
            )
          : null,
      };

      tmpGender = getGender(dataIdentification["Genre"][0]);
    }

    this.setState({
      gender: tmpGender,
      dataFilters: tmpDataFilters,
    });
  };

  onUpdateStatusSave = (status, callback) => {
    let tmpTabsIdArray = this.state.tabsIdArray;

    if (this.checkDataIdentification(0)) {
      tmpTabsIdArray[1].disabled = this.state.descriptiveStateStatus === 2;
    } else {
      tmpTabsIdArray[1].disabled = true;
    }

    if (this.checkDataIdentification(1)) {
      tmpTabsIdArray[2].disabled = this.state.marketSearchStatus === 2;
      tmpTabsIdArray[3].disabled = this.state.marketSearchStatus === 2;
    } else {
      tmpTabsIdArray[2].disabled = true;
      tmpTabsIdArray[3].disabled = true;
    }

    this.setState(
      {
        tabsIdArray: tmpTabsIdArray,
        save: status !== null ? status : this.state.save,
      },
      callback
    );
  };

  checkSource = () => {
    if (!this.refAlertSource || !this.refAlertSource.checkSource()) {
      if (
        this.state.dataIdentification &&
        this.state.dataIdentification["Compagnie"] &&
        ["MAIF", "MATMUT", "ALLIANZ"].includes(
          this.state.dataIdentification["Compagnie"][0]
        )
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  checkDataIdentification = (type) => {
    let data = [];

    data =
      this.identificationCMPRef.current.inputAdministrativeIdentification.map(
        (element) => {
          if (
            ((type === 0 && element.required1) ||
              (type === 1 && element.required2)) &&
            this.state.dataIdentification
          ) {
            if (this.state.dataIdentification.hasOwnProperty(element.value)) {
              if (this.state.dataIdentification[element.value][0]) return true;
            }
          } else if (type === 0 && !element.required1) {
            return true;
          } else if (type === 1 && !element.required2) {
            return true;
          }
          return false;
        }
      );

    data = this.identificationCMPRef.current.inputVehicleIdentification.map(
      (element) => {
        if (
          ((type === 0 && element.required1) ||
            (type === 1 && element.required2)) &&
          this.state.dataIdentification
        ) {
          if (this.state.dataIdentification.hasOwnProperty(element.value)) {
            if (this.state.dataIdentification[element.value][0]) return true;
          }
        } else if (type === 0 && !element.required1) {
          return true;
        } else if (type === 1 && !element.required2) {
          return true;
        }
        return false;
      }
    );

    if (data.includes(false)) {
      return false;
    } else {
      return true;
    }
  };

  onTabsChange = (key) => {
    if (!this.state.tabsIdArray[key].disabled) {
      this.setState({
        tabsId: key,
      });
    } else if ([1].includes(key) && this.state.descriptiveStateStatus === 2) {
      if (
        window.confirm("Êtes-vous sûr de vouloir réouvrir l'état descriptif")
      ) {
        updateStatusFolder(this.props.match.params.id, 0, 1, (result) => {
          if (result.success) {
            this.setState(
              {
                tabsId: key,
                descriptiveStateStatus: 1,
              },
              () => this.onUpdateStatusSave(null, () => {})
            );
          } else {
            alert("Une erreur est survenue");
          }
        });
      } else {
        this.setState({
          tabsId: key,
        });
      }
    } else if ([2, 3].includes(key) && this.state.marketSearchStatus === 2) {
      if (
        window.confirm("Êtes-vous sûr de vouloir réouvrir l'étude de marché")
      ) {
        updateStatusFolder(this.props.match.params.id, 1, 1, (result) => {
          if (result.success) {
            this.setState(
              {
                tabsId: key,
                marketSearchStatus: 1,
              },
              () => this.onUpdateStatusSave(null, () => {})
            );
          } else {
            alert("Une erreur est survenue");
          }
        });
      } else {
        this.setState({
          tabsId: key,
        });
      }
    }
  };

  updateFolder = (type, data, callback = () => {}) => {
    if (type === "dataIdentification") {
      this.setState({
        reference:
          data && data["Numero_Sinistre"]
            ? data["Numero_Sinistre"][0]
            : this.state.reference,
      });
    }

    if (
      (type === "dataAdministrative" &&
        this.state.marketSearchdescriptiveStateStatusStatus !== 2) ||
      type !== "dataAdministrative"
    ) {
      this.onUpdateStatusSave(1, () => {
        this.setState({ [type]: data }, () => {
          if (type === "dataIdentification") {
            this.setDataFilters();
          }

          updateFolder(this.props.match.params.id, type, data, (result) => {
            if (result.success) {
              this.onUpdateStatusSave(2, callback);
            } else {
              this.onUpdateStatusSave(0, callback);
            }
          });
        });
      });
    }
  };

  updateHideImage = () => {
    const hideImage = !this.state.hideImage;

    updateHideImage(this.props.match.params.id, hideImage, (result) => {
      if (result.success) {
        this.setState({ hideImage });
      } else {
        alert("Une erreur est survenue");
      }
    });
  };

  close = (type, result) => {
    if (result.success) {
      close(
        this.props.match.params.id,
        this.state.reference,
        type,
        this.state.date,
        result.data,
        (resultPrint) => {
          if (resultPrint.success) {
            if (this.state.tabsId === 1) {
              this.setState(
                { isLoad: false, descriptiveStateStatus: 2, tabsId: 0 },
                () => this.onUpdateStatusSave(null, () => {})
              );
            } else {
              this.setState(
                { isLoad: false, marketSearchStatus: 2, tabsId: 0 },
                () => this.onUpdateStatusSave(null, () => {})
              );
            }
          } else {
            this.setState({ isLoad: false }, () =>
              alert("Une erreur est survenue lors de la clôture")
            );
          }
        }
      );
    } else {
      this.setState({ isLoad: false }, () =>
        alert("Une erreur est survenue lors de la clôture")
      );
    }
  };

  print = (type, result) => {
    if (result.success) {
      print(
        this.props.match.params.id,
        this.state.reference,
        type,
        this.state.date,
        result.data,
        (resultPrint) => {
          if (resultPrint.success) {
            let a = document.createElement("a");
            a.href = resultPrint.file;
            if (type === 0) {
              a.download = `État descriptif ${this.state.reference}.pdf`;
            } else {
              a.download = `Étude de marché ${this.state.reference} - ${resultPrint.finalValue} EUR TTC.pdf`;
            }
            a.click();
            this.setState({ isLoad: false });
          } else {
            this.setState({ isLoad: false }, () =>
              alert("Une erreur est survenue lors de l'impression")
            );
          }
        }
      );
    } else {
      this.setState({ isLoad: false }, () =>
        alert("Une erreur est survenue lors de l'impression")
      );
    }
  };

  handlePrintClick = (type) => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        if (this.state.tabsId === 3) {
          this.summaryRef.handlePrintClick((result) => {
            if (type === 0) {
              this.close(1, result);
            } else if (type === 2) {
              this.print(1, result);
            }
          });
        } else {
          this.descriptiveStateRef.handlePrintClick((result) => {
            if (type === 0) {
              this.close(0, result);
            } else if (type === 2) {
              this.print(0, result);
            }
          });
        }
      }
    );
  };

  render() {
    return (
      <>
        <Nav
          title={`Dossier n°${this.state.reference}`}
          className="mb-10 mb-30-xl"
          back
          onBack={() => this.props.history.push("/home")}
        >
          <AlertSource
            ref={(ref) => (this.refAlertSource = ref)}
            onUpdateStatusSave={() => this.onUpdateStatusSave(null, () => {})}
            dataSelect={this.state.dataSelect}
            marketSearchStatus={this.state.marketSearchStatus}
            dataIdentification={this.state.dataIdentification}
          />
          <Save save={this.state.save} />
        </Nav>
        <TabsCustomArrow>
          <TabsCustomArrowHeader className="m-0">
            {this.state.tabsIdArray.map((element, key) => (
              <TabsCustomArrowMenu
                key={key}
                title={element.title}
                disabled={element.disabled}
                active={this.state.tabsId === key}
                onClick={(e) => this.onTabsChange(key)}
              />
            ))}
          </TabsCustomArrowHeader>
          <TabsCustomArrowBody noCard>
            <TabsCustomArrowItem active={this.state.tabsId === 0}>
              <Identification
                ref={this.identificationCMPRef}
                dataIdentification={this.state.dataIdentification}
                updateFolder={this.updateFolder}
              />
            </TabsCustomArrowItem>
            <TabsCustomArrowItem active={this.state.tabsId === 1}>
              <DescriptiveState
                ref={(ref) => {
                  this.descriptiveStateRef = ref;
                }}
                id={this.props.match.params.id}
                gender={this.state.gender}
                dataIdentification={this.state.dataIdentification}
                dataPart={this.state.dataPart}
                extendDataPart={this.state.extendDataPart}
                dataComment={this.state.dataComment}
                dataEquipment={this.state.dataEquipment}
                dataGuarding={this.state.dataGuarding}
                dataSignature={this.state.dataSignature}
                dataHistovec={this.state.dataHistovec}
                updateFolder={this.updateFolder}
                isClosed={this.state.descriptiveStateStatus === 2}
                hideImage={this.state.hideImage}
                updateHideImage={this.updateHideImage}
              />
            </TabsCustomArrowItem>
            <TabsCustomArrowItem active={this.state.tabsId === 2}>
              <MarketSearch
                id={this.props.match.params.id}
                gender={this.state.gender}
                dataFilters={this.state.dataFilters}
                dataSelect={this.state.dataSelect}
                updateFolder={this.updateFolder}
                isClosed={this.state.marketSearchStatus === 2}
                dataIdentification={this.state.dataIdentification}
              />
            </TabsCustomArrowItem>
            <TabsCustomArrowItem active={this.state.tabsId === 3}>
              <Summary
                ref={(ref) => {
                  this.summaryRef = ref;
                }}
                id={this.props.match.params.id}
                date={this.state.date}
                gender={this.state.gender}
                dataIdentification={this.state.dataIdentification}
                dataPart={this.state.dataPart}
                extendDataPart={this.state.extendDataPart}
                dataComment={this.state.dataComment}
                dataEquipment={this.state.dataEquipment}
                dataSelect={this.state.dataSelect}
                dataAdministrative={this.state.dataAdministrative}
                dataSummary={this.state.dataSummary}
                updateFolder={this.updateFolder}
                checkAd={this.checkSource()}
                isClosed={this.state.marketSearchStatus === 2}
                tabsId={this.state.tabsId}
                hideImage={this.state.hideImage}
                updateHideImage={this.updateHideImage}
              />
            </TabsCustomArrowItem>
          </TabsCustomArrowBody>
        </TabsCustomArrow>
        {[1, 3].includes(this.state.tabsId) ? (
          <FloatActionButton icon={<FontAwesomeIcon icon={faBars} />}>
            <FloatActionButtonItem
              className="bg-red white"
              icon={<FontAwesomeIcon icon={faFlag} />}
              text={"Clôturer"}
              onClick={() => this.handlePrintClick(0)}
            />
            <FloatActionButtonItem
              className="bg-orange white"
              icon={<FontAwesomeIcon icon={faPrint} />}
              text={"Imprimer"}
              onClick={() => this.handlePrintClick(2)}
            />
          </FloatActionButton>
        ) : null}
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
