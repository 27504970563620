import React from "react";
import { Col, Row } from "react-flexbox-grid";
import {
  DropzoneMultiple,
  ButtonIcon,
  Card,
  Button,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faTrash,
  faEye,
  faEyeSlash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";

import getParts from "./vehicle/parts/parts";

export default class Pictures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };
  }
  onDeletePicture = (key) => {
    if (this.props.isEdit) {
      let tmpDataPart = this.props.dataPart;

      tmpDataPart[key].picture = undefined;

      this.props.updateFolder("dataPart", tmpDataPart);
    }
  };

  render() {
    const parts = getParts(
      this.props.gender,
      this.props.dataIdentification && this.props.dataIdentification["Genre"]
        ? this.props.dataIdentification["Genre"][0]
        : null,
      this.props.dataIdentification &&
        this.props.dataIdentification["Categorie"]
        ? this.props.dataIdentification["Categorie"][0]
        : null
    );

    let dataPicture = [];
    let countPicture = 0;

    if (this.props.dataPart) {
      dataPicture = Object.keys(this.props.dataPart).map((key) => {
        if (this.props.dataPart[key].picture) {
          countPicture++;

          return (
            <Col key={key} xs={12} md={4} lg={3}>
              <div
                className="mb-10"
                style={{
                  backgroundColor: "var(--secondary-color)",
                  color: "var(--white)",
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  borderRadius: "8px",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      right: 0,
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    {!this.props.isPrint ? (
                      <ButtonIcon
                        onClick={() => this.onDeletePicture(key)}
                        className="red"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </ButtonIcon>
                    ) : null}
                  </div>
                  {parts.hasOwnProperty(key) ? <p>{parts[key].name}</p> : null}
                </div>
                {this.props.dataPart[key].picture ? (
                  <>
                    {this.props.dataPart[key].mime &&
                    typeof this.props.dataPart[key].mime === "string" &&
                    this.props.dataPart[key].mime.includes("image") ? (
                      <img
                        style={{ maxHeight: "100%", maxWidth: "100%" }}
                        src={this.props.dataPart[key].picture}
                        alt={`part-${key}`}
                      />
                    ) : (
                      <span className="pb-20 m-auto">
                        <FontAwesomeIcon icon={faFile} />
                      </span>
                    )}
                  </>
                ) : null}
              </div>
            </Col>
          );
        } else {
          return null;
        }
      });
    }

    return (
      <Card
        title="Photos / Documents"
        className="mt-10 mt-20-xl"
        action={
          <>
            <ButtonIcon
              className="p-0 black"
              small
              onClick={this.props.updateHideImage}
            >
              <FontAwesomeIcon
                icon={this.props.hideImage ? faEyeSlash : faEye}
              />
            </ButtonIcon>
          </>
        }
        disableStyleAction
      >
        <DropzoneMultiple
          ref={(ref) => (this.refDropzoneMultiple = ref)}
          className="mb-10"
          extensions={["jpeg", "jpg", "png", "JPEG", "JPG", "PNG"]}
          maxFileSize={6}
          maxImageSize={1600}
          onDrop={(files) => this.setState({ files })}
        />
        <Button
          text="Importer les photos"
          onClick={() => {
            let tmpDataPart = this.props.dataPart;

            this.state.files.forEach((file) => {
              tmpDataPart[`RD-${Object.keys(tmpDataPart).length}`] = {
                picture: file.base64,
                mime: file.mime,
              };
            });

            this.props.updateFolder("dataPart", tmpDataPart);

            this.refDropzoneMultiple.handleDeleteFiles();
          }}
          disabled={!this.state.files.length}
          className="mb-10"
          red
        >
          <FontAwesomeIcon icon={faUpload} />
        </Button>

        {!this.props.hideImage ? (
          <>
            {countPicture ? (
              <Row>{dataPicture}</Row>
            ) : (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100px",
                  border: "solid 2px var(--gray)",
                  borderRadius: "15px",
                }}
              >
                <p style={{ margin: "auto", color: "var(--gray)" }}>
                  Aucune photo
                </p>
              </div>
            )}
          </>
        ) : null}
      </Card>
    );
  }
}
