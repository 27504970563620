import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";

import { getAdsIds } from "../../requests/marketSearch";

export default class AlertSource extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countSource: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.dataSelect) !==
      JSON.stringify(prevProps.dataSelect)
    ) {
      let ids = [];

      Object.keys(this.props.dataSelect).forEach((key) => {
        if (this.props.dataSelect[key].status) {
          ids.push(key);
        }
      });

      getAdsIds(
        {
          ids,
          limit: true,
        },
        (result) => {
          if (result.success) {
            let sources = [];

            result.data.forEach((ad) => {
              if (!sources.includes(ad.source)) {
                sources.push(ad.source);
              }
            });

            this.setState(
              {
                countSource: sources.length,
              },
              this.props.onUpdateStatusSave
            );
          } else {
            this.setState(
              {
                countSource: 0,
              },
              this.props.onUpdateStatusSave
            );
          }
        }
      );
    }
  }

  checkSource = () => {
    if (this.state.countSource < 2) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    if (
      this.state.countSource < 2 &&
      this.props.dataIdentification &&
      this.props.dataIdentification["Compagnie"] &&
      ["MAIF", "MATMUT", "ALLIANZ"].includes(this.props.dataIdentification["Compagnie"][0])
    ) {
      return (
        <div
          className={"m-auto d-flex red bg-red-light mr-10"}
          style={{ padding: "0.5rem", borderRadius: "10px" }}
        >
          <FontAwesomeIcon icon={faExclamation} style={{ fontSize: "18px" }} />
          <span className="pl-10 m-auto nowrap" style={{ fontSize: "14px" }}>
            {this.state.countSource}/2 source(s) d'annonces
          </span>
        </div>
      );
    } else {
      return null;
    }
  }
}
