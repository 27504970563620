import axiosConfig from "../utils/axiosConfig";

const getFolder = (id, callback) => {
  axiosConfig("/folder/get-folder", {
    data: {
      id,
    },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const updateFolder = (id, type, data, callback) => {
  axiosConfig("/folder/update-folder", {
    data: {
      id,
      type,
      data,
    },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const updateHideImage = (id, hideImage, callback) => {
  axiosConfig("/folder/update-hide-image", {
    data: {
      id,
      hideImage,
    },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const close = (id, reference, type, date, data, callback) => {
  axiosConfig("/folder/close", {
    data: {
      id,
      reference,
      type,
      date,
      data,
    },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const print = (id, reference, type, date, data, callback) => {
  axiosConfig("/folder/print", {
    data: {
      id,
      reference,
      type,
      date,
      data,
    },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const updateStatusFolder = (id, type, value, callback) => {
  axiosConfig("/folder/update-status-folder", {
    data: {
      id,
      type,
      value,
    },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getReportHistovec = (data, callback) => {
  axiosConfig("folder/get-report-histovec", {
    data,
  })
    .then((result) => {
      callback(result.data);
    })
    .catch((err) => {
      callback({ success: false });
    });
};

export {
  getFolder,
  updateFolder,
  updateHideImage,
  close,
  print,
  updateStatusFolder,
  getReportHistovec,
};
