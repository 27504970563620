import axiosConfig from "../utils/axiosConfig";

const refreshToken = (token, url, callback) => {
  if (navigator.onLine) {
    axiosConfig("/auth/refresh-token", {
      data: {
        token,
        url,
      },
    })
      .then((res) => {
        callback(res.data);
      })
      .catch(() => callback({ success: false }));
  } else {
    callback({ success: false });
  }
};

const signout = (url, callback) => {
  if (navigator.onLine) {
    axiosConfig("/auth/signout", { data: { url } })
      .then((res) => {
        callback(res.data);
      })
      .catch(() => callback({ success: false }));
  } else {
    callback({ success: false });
  }
};

const getBrand = (category, callback) => {
  axiosConfig("general/brand", {
    data: { category },
  })
    .then((res) => {
      if (res.data.success) {
        let tmpArray = [{ value: "DEFAULT", text: "Toutes les marques" }];
        if (res.data.success) {
          tmpArray = tmpArray.concat(res.data.brand);
        }
        callback({ success: true, brands: tmpArray });
      } else {
        callback({ success: false });
      }
    })
    .catch(() => callback({ success: false }));
};

const getModel = (category, callback) => {
  axiosConfig("general/model", {
    data: { category },
  })
    .then((res) => {
      if (res.data.success) {
        callback({ success: true, models: res.data.model });
      } else {
        callback({ success: false });
      }
    })
    .catch(() => callback({ success: false }));
};

export { refreshToken, signout, getBrand, getModel };
