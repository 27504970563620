const inputAdministrativeIdentification = [
  { required1: false, required2: false, name: "Assurance", value: "Compagnie" },
  {
    required1: true,
    required2: true,
    type: "date",
    name: "Date de sinistre",
    value: "Date_Sinistre",
  },
  {
    required1: true,
    required2: true,
    name: "N° du sinistre",
    value: "Numero_Sinistre",
  },
];

const inputVehicleIdentification = [
  {
    required1: true,
    required2: true,
    name: "Genre du véhicule",
    value: "Genre",
    options: [
      { value: "M1", text: "M1" },
      { value: "M2", text: "M2" },
      { value: "M3", text: "M3" },
      { value: "N1", text: "N1" },
      { value: "N2", text: "N2" },
      { value: "N3", text: "N3" },
      { value: "O", text: "O" },
      { value: "L1", text: "L1" },
      { value: "L2", text: "L2" },
      { value: "L3", text: "L3" },
      { value: "L4", text: "L4" },
      { value: "L5", text: "L5" },
      { value: "L6", text: "L6" },
      { value: "L7", text: "L7" },
      { value: "T", text: "T" },
      { value: "C", text: "C" },
      { value: "R", text: "R" },
    ],
  },
  { required1: true, required2: true, name: "Marque", value: "Constructeur" },
  { required1: true, required2: true, name: "Modèle", value: "Modele" },
  { required1: false, required2: false, name: "Finition", value: "Finition" },
  {
    required1: true,
    required2: true,
    name: "Carrosserie",
    value: "Carrosserie",
  },
  {
    required1: true,
    required2: true,
    name: "Énergie",
    value: "Energie",
    options: [
      { value: "NC", text: "Sans énergie" },
      { value: "ES", text: "Essence" },
      { value: "GO", text: "Diesel" },
      { value: "HY", text: "Hybride" },
      { value: "EL", text: "Électrique" },
    ],
  },
  {
    required1: true,
    required2: true,
    name: "Nombre de place",
    value: "Nb_Places",
  },
  {
    required1: true,
    required2: true,
    name: "Puissance",
    value: "Puissance",
  },
  {
    required1: true,
    required2: true,
    type: "year",
    name: "Année modèle",
    value: "Annee_Modele",
  },
  { required1: false, required2: true, name: "Kilométrage", value: "Kms" },
  {
    required1: true,
    required2: true,
    type: "date",
    name: "Date de mise en circulation",
    value: "Date1MEC",
  },
  {
    required1: true,
    required2: true,
    name: "Catégorie",
    value: "Categorie",
    options: [
      { value: "(1/A) Petits Véhicules", text: "(1/A) Petits Véhicules" },
      {
        value: "(2/B) Gamme Moyenne Inférieure",
        text: "(2/B) Gamme Moyenne Inférieure",
      },
      {
        value: "(3/C) Gamme Moyenne Supérieure",
        text: "(3/C) Gamme Moyenne Supérieure",
      },
      { value: "(4/D) Gamme Haute", text: "(4/D) Gamme Haute" },
      {
        value: "(7/G) Véhicule de Société Dérivé de V.L",
        text: "(7/G) Véhicule de Société Dérivé de V.L",
      },
      {
        value: "(8/H) Véhicule Utilitaire Léger",
        text: "(8/H) Véhicule Utilitaire Léger",
      },
      { value: "(9/J) 4x4", text: "(9/J) 4x4" },
      { value: "(10/K) Motos < 50cc", text: "(10/K) Motos < 50cc" },
      { value: "(11/L) Motos 50 >< 125cc", text: "(10/L) Motos 50 >< 125" },
      { value: "(12/M) Motos > 125", text: "(10/M) Motos > 125" },
    ],
  },
];

export { inputAdministrativeIdentification, inputVehicleIdentification };
