import React from "react";
import {
  Card,
  FormInput,
  FormSelect,
  FormCheckbox,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import { getYearOptions, getMileageOptions } from "../../utils/dataSelect";

import { getBrand, getModel } from "../../requests/general";

import AuthContext from "../../context/AuthContext";

export default class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      descriptiveStateStatus: "DEFAULT",
      marketSearchStatus: "DEFAULT",
      category: "DEFAULT",
      brand: "DEFAULT",
      model: "DEFAULT",
      year: "DEFAULT",
      minMileage: "DEFAULT",
      maxMileage: "DEFAULT",
      global: true,
      categoryOptions: [
        { value: "DEFAULT", text: "Toutes les catégories" },
        { value: "0", text: "Voiture" },
        { value: "1", text: "Moto" },
        { value: "2", text: "Utilitaire" },
        { value: "3", text: "Caravaning" },
      ],
      brandOptions: [],
      modelOptions: null,
      yearOptions: [],
      minMileageOptions: [],
      maxMileageOptions: [],
    };
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name === "category" && ["0", "1"].includes(value)) {
          getBrand(this.state.category, (result) => {
            if (result.success) {
              this.setState(
                { brandOptions: result.brands, brand: "DEFAULT" },
                () => this.props.onDataChange(this.getData())
              );
            } else {
              this.setState({ brandOptions: [], brand: "DEFAULT" }, () =>
                this.props.onDataChange(this.getData())
              );
            }
          });
          getModel(this.state.category, (result) => {
            if (result.success) {
              this.setState(
                { modelOptions: result.models, model: "DEFAULT" },
                () => this.props.onDataChange(this.getData())
              );
            } else {
              this.setState({ modelOptions: [], model: "DEFAULT" }, () =>
                this.props.onDataChange(this.getData())
              );
            }
          });
        } else if (name === "brand") {
          this.setState({ model: "DEFAULT" }, () =>
            this.props.onDataChange(this.getData())
          );
        } else if (
          ["search", "descriptiveStateStatus", "marketSearchStatus"].includes(
            name
          )
        ) {
          this.props.onDataChange(this.getData(), false);
        } else {
          this.props.onDataChange(this.getData());
        }
      }
    );
  };

  getData = () => {
    return {
      search: this.state.search,
      descriptiveStateStatus: this.state.descriptiveStateStatus,
      marketSearchStatus: this.state.marketSearchStatus,
      category: this.state.category,
      brand: this.state.brand,
      model: this.state.model,
      year: this.state.year,
      minMileage: this.state.minMileage,
      maxMileage: this.state.maxMileage,
      global: this.state.global,
    };
  };

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    this.setState(
      {
        [name]: checked,
      },
      () => this.props.onDataChange(this.getData())
    );
  };

  componentDidMount() {
    const mileageOptions = [
      { value: "DEFAULT", text: "Tous les kilométrages" },
    ].concat(getMileageOptions());
    const yearOptions = [
      { value: "DEFAULT", text: "Toutes les années" },
    ].concat(getYearOptions());

    this.setState({
      minMileageOptions: mileageOptions,
      maxMileageOptions: mileageOptions,
      yearOptions: yearOptions,
    });
  }

  render() {
    return (
      <Card
        title="Filtres"
        className="mb-10 mb-30-xl xl-h-100 d-flex flex-column"
        classNameChildren="d-flex flex-column xl-h-100 overflow-y-auto"
      >
        <FormInput
          className="mb-10"
          title="Numéro de sinistre"
          value={this.state.search}
          name="search"
          onChange={this.handleInputChange}
          placeholder=""
        >
          <FontAwesomeIcon className="icon" icon={faSearch} />
        </FormInput>
        {!this.props.isMobile ? (
          <>
            <FormSelect
              className="mb-10"
              title="État descriptif"
              name="descriptiveStateStatus"
              value={this.state.descriptiveStateStatus}
              ignore={true}
              options={[
                { value: "DEFAULT", text: "Tous les états" },
                { value: "0,1", text: "À faire + En cours" },
                { value: "0", text: "À faire" },
                { value: "1", text: "En cours" },
                { value: "2", text: "Finalisé" },
              ]}
              onChange={this.handleInputChange}
            />
            <FormSelect
              className="mb-auto pb-10"
              title="Étude de marché"
              name="marketSearchStatus"
              value={this.state.marketSearchStatus}
              ignore={true}
              options={[
                { value: "DEFAULT", text: "Tous les états" },
                { value: "0,1", text: "À faire + En cours" },
                { value: "0", text: "À faire" },
                { value: "1", text: "En cours" },
                { value: "2", text: "Finalisé" },
              ]}
              onChange={this.handleInputChange}
            />
            <FormSelect
              className="mb-10"
              title="Catégorie"
              name="category"
              value={this.state.category}
              ignore={true}
              options={this.state.categoryOptions}
              onChange={this.handleInputChange}
            />
            {["0", "1"].includes(this.state.category) ? (
              <FormSelect
                className="mb-10"
                title="Marque"
                name="brand"
                value={this.state.brand}
                ignore={true}
                options={this.state.brandOptions}
                onChange={this.handleInputChange}
              />
            ) : null}
            {["0", "1"].includes(this.state.category) ? (
              <FormSelect
                className="mb-10"
                title="Modèle"
                name="model"
                value={this.state.model}
                ignore={true}
                options={
                  this.state.brand !== "DEFAULT" && this.state.modelOptions
                    ? this.state.modelOptions[this.state.brand]
                    : null
                }
                onChange={this.handleInputChange}
              />
            ) : null}
            <FormSelect
              className="mb-10"
              title="Année"
              name="year"
              value={this.state.year}
              ignore={true}
              options={this.state.yearOptions}
              onChange={this.handleInputChange}
            />
            <FormSelect
              className="mb-10"
              title="Kilométrage min."
              name="minMileage"
              value={this.state.minMileage}
              ignore={true}
              options={this.state.minMileageOptions}
              onChange={this.handleInputChange}
            />
            <FormSelect
              className="mb-10"
              title="Kilométrage max."
              name="maxMileage"
              value={this.state.maxMileage}
              ignore={true}
              options={this.state.maxMileageOptions}
              onChange={this.handleInputChange}
            />
            {this.context.global ? (
              <FormCheckbox
                className="mb-10 mt-auto nowrap"
                text="Visualisation globale"
                name="global"
                checked={this.state.global}
                onChange={this.handleCheckboxChange}
              />
            ) : null}
          </>
        ) : null}
      </Card>
    );
  }
}
